import ApiService from "./api.service";

export function agentReport(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/report/agentReport", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getDeviceStatus(agent_id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/report/getDeviceStatus", { agent_id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getGunStatus(agent_id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/report/getGunStatus", { agent_id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}
