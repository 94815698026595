<template>
  <div class="with-nav-bar">
    <van-row class="bg-liner py-3">
      <van-col span="12" class="text-center">
        <van-circle
          text="设备"
          size="80px"
          v-model:current-rate="deviceRate"
          :color="gradientColor"
          :rate="
            report.totalDevice > 0
              ? new Number(report.online / report.totalDevice).toFixed(2) * 100
              : 0
          "
          :speed="100"
        />
        <div>
          <small>
            <span class="text-success">{{ report.online }}在线</span>&nbsp;
            <span class="text-danger">{{ report.offline }}离线</span>
          </small>
        </div>
      </van-col>
      <van-col span="12" class="text-center">
        <van-circle
          text="端口"
          size="80px"
          v-model:current-rate="gunRate"
          :color="gradientColor"
          :rate="
            report.totalGun > 0
              ? new Number(report.using / report.totalGun).toFixed(2) * 100
              : 0
          "
          :speed="100"
        />
        <div>
          <small>
            <span class="text-success">{{ report.free }}空闲</span>&nbsp;
            <span class="text-danger">{{ report.using }}占用</span>
          </small>
        </div>
      </van-col>
    </van-row>
    <van-nav-bar
      v-if="agent.level == 2"
      title="充电站管理"
      left-text="返回"
      :right-text="!state.loading ? '添加' : ''"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
      @click-right="!state.loading && $router.push({ name: 'StationForm' })"
    />
    <van-nav-bar
      v-else
      title="充电站管理"
      left-text="返回"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
    />
    <van-field
      v-if="agent.level == 1"
      v-model="state.agent_name"
      label=""
      placeholder="点击选择代理商"
      input-align="center"
      readonly
      clickable
      @click="state.showPicker = true"
    />
    <van-popup
      v-if="agent.level == 1"
      v-model:show="state.showPicker"
      position="bottom"
      round
    >
      <van-picker
        confirm-button-text="选择"
        :columns="state.columns"
        @confirm="chooseAgent"
        @cancel="state.showPicker = false"
      />
    </van-popup>
    <van-empty
      v-if="state.empty"
      image="network"
      :description="state.empty_text"
    />
    <van-list
      v-else
      v-model:loading="state.loading"
      v-model:error="state.error"
      finished-text="没有更多了"
      error-text="拉取失败，点击重新加载"
      :finished="state.finished"
      @load="loadPage"
    >
      <van-cell
        v-for="(item, index) in state.items"
        icon="wap-home-o"
        :key="index"
        :title="item.name"
        :to="{ name: 'StationDetail', query: { id: item.id } }"
        is-link
      />
    </van-list>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import {
  List,
  Toast,
  Empty,
  Field,
  Popup,
  Picker,
  Circle,
  Row,
  Col,
} from "vant";
import { getAllSubAgent } from "@/api/agent.service";
import { getStationList } from "@/api/station.service";
import { getDeviceStatus, getGunStatus } from "@/api/report.service";
import { useStore } from "vuex";
export default {
  components: {
    [List.name]: List,
    [Empty.name]: Empty,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Circle.name]: Circle,
    [Row.name]: Row,
    [Col.name]: Col,
  },

  setup() {
    const store = useStore();
    const agent = store.getters.agentInfo;
    const state = reactive({
      page: 1,
      items: [],
      loading: true,
      error: false,
      finished: false,
      empty: false,
      empty_text: "暂无任何充电站",

      // 选择框
      agent_id: 0,
      agent_name: "",
      columns: [{ values: [], defaultIndex: 0 }],
      stationList: [],
    });

    const loadAgent = async () => {
      try {
        const { result } = await getAllSubAgent({ parent_id: agent.id });
        state.stationList = result.items;
        state.stationList.map((item, index) => {
          // 默认选中第一个
          if (index == 0) {
            state.agent_id = item.id;
            state.agent_name = item.agent_name;
            loadDeviceStatus(state.agent_id);
            loadGunStatus(state.agent_id);
          }
          state.columns[0].values.push(item.agent_name);
        });
        // 只需显示出 <van-list> 会自动调用 loadPage()
        state.empty = false;
        // loadPage();
      } catch (error) {
        console.log(error);
      }
    };

    const loadPage = async () => {
      if (agent.level == 1 && !state.agent_name) {
        state.empty = true;
        state.empty_text = "请选择代理商";
        return;
      }
      try {
        const { result } = await getStationList(state.page, state.agent_name);
        if (result.items.length > 0) {
          state.items.push(...result.items);
          state.page++;
          state.empty = false;
        }
        if (!result.hasNext || result.items.length == 0) {
          state.finished = true;
        }
        state.loading = false;
      } catch (error) {
        state.loading = false;
        state.error = true;
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const chooseAgent = (value) => {
      // 注意: 由于 columns 配置为数组形式, 这里取得的值也是数组
      // 虽然只有一列, 但是要设定默认选中的项目, 也需要配置为多列数组的形式
      state.showPicker = false;
      state.agent_name = value[0];
      state.stationList.map((item) => {
        if (item.name == value[0]) {
          state.agent_id = item.id; // 用于获取报表数据
          return;
        }
      });
      state.page = 1;
      state.items = [];
      loadPage();
      loadDeviceStatus(state.agent_id);
      loadGunStatus(state.agent_id);
    };

    const gradientColor = {
      "0%": "#3fecff",
      "100%": "#6149f6",
    };
    const deviceRate = ref(0);
    const gunRate = ref(0);
    const report = reactive({});
    const loadDeviceStatus = async (agent_id) => {
      try {
        const { result } = await getDeviceStatus(agent_id);
        report.online = result.online;
        report.offline = result.offline;
        report.totalDevice = result.total;
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };
    const loadGunStatus = async (agent_id) => {
      try {
        const { result } = await getGunStatus(agent_id);
        report.free = result.free;
        report.using = result.using;
        report.totalGun = result.total;
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    onMounted(() => {
      if (agent.level == 1) {
        loadAgent();
      } else {
        loadDeviceStatus(0);
        loadGunStatus(0);
      }
    });

    return {
      agent,
      state,
      loadPage,
      chooseAgent,
      gradientColor,
      deviceRate,
      gunRate,
      report,
    };
  },
};
</script>
